@import '../../../styles/variables.sass';

.PrivacyPolicePage {
	height: 100%;
	overflow-y: auto;

	&__header {
		height: 200px;
		background-color: $dark-grey-2;
		box-shadow: 0 0 4px 0 rgba(0,0,0,0.12), 0 1px 4px 0 rgba(0,0,0,0.24);
		display: grid;
		align-items: center;
		justify-content: center;

		&>* {
			justify-self: center; } }

	&__content {
		padding: 1rem;

		@media screen and (min-width: $break-sm) {
			padding: 2rem 4rem; }

		.Text {
			margin: 1rem 0; }

		ul {
			li {
				&::marker {
					color: $light-grey-3; } } } } }
